<template>
  <div class="box dashboard-graph-card">
    <div class="dashboard-graph-card-titlebar">
      <h5 :class="{ 'is-dimmed is-blurred': !hasPro }">{{ title }}</h5>

      <div v-if="!isLoading" class="toolbar" :class="{ 'is-dimmed is-blurred': !hasPro }">
        <slot name="toolbar" />
      </div>
    </div>

    <div class="graph-content" :class="{ 'is-blurred': !hasPro }">
      <div v-if="isLoading" class="dashboard-graph-card-loader-container">
        <InlineLoader class="dashboard-graph-card-loader" />
      </div>

      <slot v-else />
    </div>

    <DashboardRequiresSubscriptionOverlay
      :title="title"
      :subtitle="subtitle"
      title-size="4">
    </DashboardRequiresSubscriptionOverlay>

    <UpgradeSubscriptionBadge class="dashboard-graph-card-upgrade-subscription-badge" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DashboardRequiresSubscriptionOverlay from '../DashboardRequiresSubscriptionOverlay.vue'
import UpgradeSubscriptionBadge from '@/modules/subscription/components/UpgradeSubscriptionBadge.vue'

export default {
  components: {
    DashboardRequiresSubscriptionOverlay,
    UpgradeSubscriptionBadge
  },

  props: {
    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters({
      reports: 'huntarea/reports/getAll',
      hasPro: 'subscription/hasPro'
    }),

    isLoading () {
      return this.reports === null
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-graph-card {
  position: relative;

  .is-blurred {
    filter: blur(1px);
  }

  .dashboard-graph-card-titlebar {
    position: relative;
    text-align: center;
    margin-bottom: 1.25rem;

    h5 {
      font-weight: 600;
      font-size: 1rem;
      display: inline-block;
    }

    .toolbar {
      display: inline;
      position: absolute;
      right: 0;
    }
  }

  .graph-content {
    height: 200px;
    position: relative;

    .dashboard-graph-card-loader-container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .dashboard-graph-card-loader {
        font-size: 3rem;
      }
    }
  }

  .dashboard-graph-card-upgrade-subscription-badge {
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
  }
}
</style>
