<template>
  <div class="box has-text-centered">
    <InlineLoader v-if="loading" />

    <h1 v-else class="title is-2 is-marginless">
      {{ getValue }}
    </h1>

    <div class="subtitle is-6 is-marginless">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    value: {
      type: Number,
      default: 0
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getValue () {
      return '' + this.value
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
