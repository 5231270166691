<template>
  <Dropdown
    :size="size"
    :text="$t('huntArea.dashboard.game.select')"
    :disabled="disabled"
    alignment="right"
    :auto-close="false">
    <DropdownItem v-for="(game, i) in games" :key="i">
      <input
        v-model="game.selected"
        type="checkbox"
        class="push-right"
        @change="onChange(game)" />
      {{ game.name }}
    </DropdownItem>

    <DropdownItem v-if="games.length === 0">
      <span class="has-text-grey">
        {{ $t('huntArea.dashboard.game.none') }}
      </span>
    </DropdownItem>
  </Dropdown>
</template>

<script>
export default {
  props: {
    games: {
      type: Array,
      required: true
    },

    size: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onChange (game) {
      this.$emit('on-game-selected', game)
    }
  }
}
</script>
