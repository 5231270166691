<template>
  <MapControl position="top-right" class="dashboard-map-toolbar">
    <Toolbar>
      <ToolbarItem>
        <DashboardGameDropdown :games="games" @on-game-selected="onGameSelected" />
      </ToolbarItem>

      <ToolbarItem>
        <SegmentedControl
          v-model="eventType"
          :segments="segments"
          type="primary"
          :fit-to-parent="false">
        </SegmentedControl>
      </ToolbarItem>
    </Toolbar>
  </MapControl>
</template>

<script>
import {
  DASHBOARD_EVENT_TYPE_ALL,
  DASHBOARD_EVENT_TYPE_KILLS,
  DASHBOARD_EVENT_TYPE_OBSERVATIONS
} from '../../store/dashboardStore'

import { mapProperty } from '@/common/utils/vuexHelpers'

import MapControl from '@/modules/map/components/controls/MapControl.vue'
import DashboardGameDropdown from '../DashboardGameDropdown.vue'

export default {
  components: {
    MapControl,
    DashboardGameDropdown
  },

  props: {
    games: {
      type: Array,
      required: true
    }
  },

  computed: {
    eventType: mapProperty('huntarea/dashboard', 'getEventType', 'setEventType'),

    segments () {
      return [{
        title: this.$i18n.t('huntArea.dashboard.events.kills'),
        value: DASHBOARD_EVENT_TYPE_KILLS
      }, {
        title: this.$i18n.t('huntArea.dashboard.events.observations'),
        value: DASHBOARD_EVENT_TYPE_OBSERVATIONS
      }, {
        title: this.$i18n.t('huntArea.dashboard.events.all'),
        value: DASHBOARD_EVENT_TYPE_ALL
      }]
    }
  },

  methods: {
    onGameSelected () {
      this.$emit('on-game-selected')
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-map-toolbar {
  // Hard coded position and does currently not take into consideration
  // that the button (45px) most likely will get smaller on handhelds
  right: calc(1.5rem + 45px + 1.5rem) !important;
}
</style>
