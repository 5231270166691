<template>
  <Badge
    v-if="isVisible"
    type="primary"
    class="upgrade-subscription-badge"
    :light="true"
    :text="$t('subscription.upgrade')">
  </Badge>
</template>

<script>
import { mapGetters } from 'vuex'

// Even though we don't declare the CSS class "upgrade-subscription-badge" it must be set to allow
// nested classes as in HuntsPlanHuntButton.vue

export default {
  computed: {
    ...mapGetters({
      hasPro: 'subscription/hasPro',
      isLoading: 'subscription/isLoading'
    }),

    isVisible () {
      return !this.hasPro && !this.isLoading
    }
  }
}
</script>
