<template>
  <MapControl position="bottom">
    <div class="dashboard-map-slider level is-mobile">
      <div class="level-left">
        <div class="level-item push-right">
          {{ startDateFormatted }}
        </div>
      </div>

      <div class="level-item">
        <div class="range-slider-wrapper">
          <span v-show="reports !== null" ref="range-slider-value" class="range-slider-value">
            <span>{{ currentDateFormatted }}</span>
          </span>

          <div class="range-slider">
            <input
              v-model="startDateOffsetInDays"
              :step="1"
              type="range"
              :min="0"
              :max="totalDaysInPeriod" />
          </div>
        </div>

        <div class="level-item">
          {{ endDateFormatted }}
        </div>
      </div>

      <div class="level-right">
        <div class="select is-small">
          <select v-model="year">
            <option
              v-for="(y, i) in years"
              :key="i"
              :value="y"
              :selected="year === y">
              {{ y }}
            </option>

            <option :value="null" :selected="year === null">
              {{ $t('huntArea.dashboard.years.all') }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </MapControl>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapProperty } from '@/common/utils/vuexHelpers'
import { getYears } from '@/modules/huntArea/report/utils/reportUtils.js'
import MapControl from '@/modules/map/components/controls/MapControl.vue'

export default {
  components: {
    MapControl
  },

  computed: {
    ...mapGetters({
      totalDaysInPeriod: 'huntarea/dashboard/getTotalDaysInPeriod',
      reports: 'huntarea/reports/getAll',
      startDate: 'huntarea/dashboard/getStartDate',
      endDate: 'huntarea/dashboard/getEndDate',
      currentDate: 'huntarea/dashboard/getCurrentDate'
    }),

    startDateOffsetInDays: mapProperty('huntarea/dashboard', 'getStartDateOffsetInDays', 'setStartDateOffsetInDays'),

    year: {
      get () {
        return this.$store.getters['huntarea/dashboard/getYear']
      },

      set (year) {
        this.$store.dispatch('huntarea/dashboard/setYear', year)
      }
    },

    years () {
      let years = []

      if (this.reports !== null) {
        years = getYears(this.reports)
        years.sort((a, b) => Number(b) - Number(a))
      }

      return years
    },

    startDateFormatted () {
      return this.dateFormat(this.startDate)
    },

    endDateFormatted () {
      return this.dateFormat(this.endDate)
    },

    currentDateFormatted () {
      const isToday = this.$dayjs(this.currentDate).isSame(new Date(), 'day')
      const isMultipleYears = this.startDate.getFullYear() !== this.endDate.getFullYear()

      let date = this.$dayjs(this.currentDate).format(isMultipleYears ? 'MMMM YYYY' : 'MMMM')
      date = date.charAt(0).toUpperCase() + date.slice(1)

      return isToday ? this.$i18n.t('huntArea.dashboard.years.today') : date
    }
  },

  watch: {
    reports () {
      this.setDefaultYear()
    },

    startDateOffsetInDays () {
      this.updateRangeSliderThumbValue()
    }
  },

  mounted () {
    if (this.reports !== null) {
      this.setDefaultYear()
    }
  },

  methods: {
    dateFormat (date) {
      const result = this.$dayjs(date).format('MMM YYYY')
      return result.charAt(0).toUpperCase() + result.slice(1) // Capitalize first letter
    },

    setDefaultYear () {
      this.year = this.years.length > 0 ? this.years[0] : null
      this.updateRangeSliderThumbValue()
    },

    updateRangeSliderThumbValue () {
      const newValue = (this.startDateOffsetInDays * 100) / this.totalDaysInPeriod
      const position = 10 - (newValue * 0.2)

      const rangeValue = this.$refs['range-slider-value']
      rangeValue.style.left = `calc(${newValue}% + (${position}px))`
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-map-slider {
  background-color: white;
  border-radius: 6px;
  padding: 0.5rem;
  margin-bottom: 0 !important;

  .range-slider-wrapper {
    position: relative;
    width: 100%;
    margin-right: 1rem;

    .range-slider-value {
      position: absolute;
      top: -18px;

      span {
        width: 200px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        color: black;
        font-size: 12px;
        display: block;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 6px;
      }
    }
  }
}
</style>
