<template>
  <DashboardNumberCard
    :title="$t('huntArea.dashboard.game.killed')"
    :value="totalKillCount"
    :loading="isLoading">
  </DashboardNumberCard>
</template>

<script>

import { mapGetters } from 'vuex'

import DashboardNumberCard from './DashboardNumberCard.vue'

export default {
  components: {
    DashboardNumberCard
  },

  computed: {
    ...mapGetters({
      reports: 'huntarea/reports/getAll',
      totalKillCount: 'huntarea/reports/getTotalKillCount'
    }),

    isLoading () {
      return this.reports === null
    }
  }
}
</script>
