<template>
  <DashboardReportsGraphCard
    :title="$t('huntArea.dashboard.game.observed')"
    :subtitle="$t('huntArea.dashboard.pro.observed')"
    :types="['observation']">
  </DashboardReportsGraphCard>
</template>

<script>
import DashboardReportsGraphCard from './DashboardReportsGraphCard.vue'

export default {
  components: {
    DashboardReportsGraphCard
  }
}
</script>
