<template>
  <Wrapper class="page-dashboard">
    <Titlebar :title="huntArea.name" />

    <div class="default-spacing is-paddingless-top">
      <div v-if="hasActiveHunt && huntArea" class="notification is-primary">
        <p class="is-marginless" v-html="$t('dashboard.ongoingHuntOnHuntingGround', { huntArea: huntArea.name })"></p>
      </div>

      <div class="columns">
        <div class="column">
          <DashboardMembersCard />
        </div>

        <div class="column">
          <DashboardTotalAreaCard />
        </div>

        <div class="column">
          <DashboardTotalKillsCard />
        </div>

        <div class="column">
          <DashboardTotalObservationsCard />
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <DashboardMapCard />
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <DashboardDownedGameGraphCard />
        </div>

        <div class="column">
          <DashboardObservedGameGraphCard />
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <DashboardDownedAndObservedGameGraphCard />
        </div>

        <div class="column">
          <DashboardManHoursGraphCard />
        </div>
      </div>
    </div>
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex'

import Titlebar from '@/modules/core/components/layout/Titlebar.vue'

import DashboardMembersCard from './cards/DashboardMembersCard.vue'
import DashboardTotalAreaCard from './cards/DashboardTotalAreaCard.vue'
import DashboardTotalKillsCard from './cards/DashboardTotalKillsCard'
import DashboardTotalObservationsCard from './cards/DashboardTotalObservationsCard.vue'

import DashboardDownedGameGraphCard from './graphs/DashboardDownedGameGraphCard.vue'
import DashboardObservedGameGraphCard from './graphs/DashboardObservedGameGraphCard.vue'
import DashboardDownedAndObservedGameGraphCard from './graphs/DashboardDownedAndObservedGameGraphCard.vue'
import DashboardManHoursGraphCard from './graphs/DashboardManHoursGraphCard.vue'

import DashboardMapCard from './map/DashboardMapCard.vue'

export default {
  components: {
    Titlebar,

    DashboardMembersCard,
    DashboardTotalAreaCard,
    DashboardTotalObservationsCard,
    DashboardTotalKillsCard,
    DashboardDownedGameGraphCard,
    DashboardObservedGameGraphCard,
    DashboardDownedAndObservedGameGraphCard,
    DashboardManHoursGraphCard,

    DashboardMapCard
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      hasActiveHunt: 'huntarea/hasActiveHunt'
    })
  }
}
</script>
