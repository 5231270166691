<template>
  <div v-if="!hasPro" class="dashboard-requires-subscription-overlay">
    <div>
      <Heading :size="titleSize" :text="title" />
      <Paragraph class="dashboard-requires-subscription-overlay-subtitle" :text="subtitle" />

      <Button
        v-if="showCta"
        type="primary"
        :title="$t('subscription.upgrade')"
        @click="getPro">
      </Button>
    </div>

    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    titleSize: {
      type: String,
      default: '4'
    },

    subtitle: {
      type: String,
      default: ''
    },

    showCta: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      hasPro: 'subscription/hasPro'
    })
  },

  methods: {
    getPro () {
      this.$router.push({ name: 'AccountSubscription' })
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-requires-subscription-overlay {
  position: absolute;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);

  .dashboard-requires-subscription-overlay-subtitle {
    font-size: 20px;
  }
}
</style>
