<template>
  <MapHeatmapLayer
    v-if="hasData && isVisible"
    :data="data"
    :gradient="gradient"
    :radius="15"
    :opacity="0.5"
    :dissipating="true"
    :max-intensity="1">
  </MapHeatmapLayer>
</template>

<script>
import { mapGetters } from 'vuex'
import MapHeatmapLayer from '@/modules/map/components/layers/MapHeatmapLayer.vue'

export default {
  components: {
    MapHeatmapLayer
  },

  props: {
    types: {
      type: Array,
      required: true
    },

    gradient: {
      type: Array,
      required: true
    },

    games: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      reports: 'huntarea/reports/getAll',
      eventType: 'huntarea/dashboard/getEventType',
      startDate: 'huntarea/dashboard/getStartDate',
      currentDate: 'huntarea/dashboard/getCurrentDate'
    }),

    hasData () {
      return this.reports !== null
    },

    isVisible () {
      return this.types.includes(this.eventType) || this.eventType === 'all'
    },

    data () {
      let data = []

      const currentDate = this.$dayjs(this.currentDate).add(1, 'day').toDate()

      this.reports.forEach(report => {
        report.events.forEach(event => {
          if (this.types.includes(event.type) && event.coordinate !== null && event.timestamp !== null) {
            if (event.timestamp >= this.startDate && event.timestamp <= currentDate) {
              event.games.forEach(game => {
                if (this.isGameSelected(game)) {
                  data.push({
                    location: event.coordinate,
                    weight: game.count
                  })
                }
              })
            }
          }
        })
      })

      return data
    }
  },

  methods: {
    isGameSelected (eventGame) {
      for (const game of this.games) {
        if (game.name === eventGame.animalName && game.selected) {
          return true
        }
      }
      return false
    }
  }
}
</script>
