<script>
/* global google */
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },

    visible: {
      type: Boolean,
      default: true
    },

    gradient: {
      type: Array,
      default: () => []
    },

    radius: {
      type: Number,
      default: 0.12
    },

    maxIntensity: {
      type: Number,
      default: null
    },

    dissipating: {
      type: Boolean,
      default: false
    },

    opacity: {
      type: Number,
      default: 0.6
    }
  },

  inject: [
    'getMap'
  ],

  data () {
    return {
      map: null,
      heatmap: null
    }
  },

  watch: {
    visible () {
      this.updateVisibility()
    },

    data () {
      this.updateData()
    },

    maxIntensity () {
      this.updateMaxIntensity()
    },

    opacity () {
      this.updateOpacity()
    }
  },

  async mounted () {
    this.map = await this.getMap()

    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: this.getData(),
      map: this.map,
      radius: this.radius,
      maxIntensity: this.maxIntensity,
      opacity: this.opacity,
      dissipating: this.dissipating,
      gradient: this.gradient
    })

    this.updateVisibility()
  },

  destroyed () {
    this.heatmap.setMap(null)
  },

  render () {
    return ''
  },

  methods: {
    updateVisibility () {
      this.heatmap.setMap(this.visible ? this.map : null)
    },

    updateData () {
      if (this.heatmap !== null) {
        this.heatmap.setData(this.getData())
      }
    },

    updateMaxIntensity () {
      this.heatmap.setOptions({
        maxIntensity: this.maxIntensity
      })
    },

    updateOpacity () {
      this.heatmap.setOptions({
        opacity: this.opacity
      })
    },

    getData () {
      return this.data.map(weightedLocation => {
        return {
          location: new google.maps.LatLng(weightedLocation.location.lat, weightedLocation.location.lng),
          weight: weightedLocation.weight
        }
      })
    }
  }
}
</script>
