<template>
  <DashboardNumberCard
    :title="$t('map.general.hectare')"
    :value="areaInHa"
    :loading="boundary === null">
  </DashboardNumberCard>
</template>

<script>

import { mapGetters } from 'vuex'

import DashboardNumberCard from './DashboardNumberCard.vue'

export default {
  components: {
    DashboardNumberCard
  },

  computed: {
    ...mapGetters({
      boundary: 'map/boundary/boundary'
    }),

    areaInHa () {
      return this.boundary !== null ? Number((this.boundary.computeArea() / 10000).toFixed(0)) : 0
    }
  }
}
</script>
