<template>
  <DashboardNumberCard
    :title="$t('dashboard.members')"
    :value="members !== null ? members.length : 0"
    :loading="members === null">
  </DashboardNumberCard>
</template>

<script>

import { mapGetters } from 'vuex'

import DashboardNumberCard from './DashboardNumberCard.vue'

export default {
  components: {
    DashboardNumberCard
  },

  computed: {
    ...mapGetters({
      members: 'huntarea/members/members'
    })
  }
}
</script>
