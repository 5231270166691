<template>
  <DashboardGraphCard :title="title" :subtitle="subtitle">
    <DashboardGameDropdown
      v-if="games.length > 0"
      slot="toolbar"
      size="small"
      :games="games"
      @on-game-selected="onGameSelected">
    </DashboardGameDropdown>

    <Apexchart
      type="line"
      height="100%"
      :options="options"
      :series="series">
    </Apexchart>
  </DashboardGraphCard>
</template>

<script>
import { mapGetters } from 'vuex'
import { isValidGame } from '@/modules/huntArea/report/utils/reportUtils'
import VueApexCharts from 'vue-apexcharts'

import DashboardGraphCard from './DashboardGraphCard.vue'
import DashboardGameDropdown from '../DashboardGameDropdown.vue'

export default {
  components: {
    DashboardGraphCard,
    DashboardGameDropdown,
    Apexchart: VueApexCharts
  },

  props: {
    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      required: false,
      default: ''
    },

    types: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      games: [],
      series: [],
      months: []
    }
  },

  computed: {
    ...mapGetters({
      reports: 'huntarea/reports/getAll',
      language: 'ui/language',
      hasPro: 'subscription/hasPro'
    }),

    isLoading () {
      return this.reports === null
    },

    events () {
      const events = []
      const reports = this.reports || []

      reports.forEach(report => {
        report.events.forEach(event => {
          if (this.types.includes(event.type)) {
            events.push(event)
          }
        })
      })

      return events
    },

    options () {
      return {
        chart: {
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        legend: {
          showForSingleSeries: true,
          showForNullSeries: false
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        markers: {
          size: 4
        },
        grid: {
          column: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        colors: [
          '#83A5AA', '#F1A269', '#687B81', '#C05000', '#1C2F35'
        ],

        xaxis: {
          categories: this.months,
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          decimalsInFloat: 0,
          axisTicks: {
            show: true
          },

          axisBorder: {
            show: true,
            color: 'rgb(150, 150, 150)'
          }
        }
      }
    }
  },

  watch: {
    language () {
      this.updateMonths()
    }
  },

  mounted () {
    // If we've already loaded the data, update the chart now.
    // Otherwise wait for the watcher.
    if (this.reports !== null) {
      this.updateData()
    } else {
      this.$watch('reports', function () {
        this.updateData()
      })
    }
  },

  methods: {
    updateData () {
      this.updateMonths()
      this.updateGames()
      this.updateChart()
    },

    flatten (object) {
      const array = []
      for (const key in object) {
        array.push(object[key])
      }

      return array
    },

    updateGames () {
      const games = []

      this.events.forEach(event => {
        event.games.forEach(game => {
          if (isValidGame(game)) {
            if (games[game.animalId] === undefined) {
              games[game.animalId] = {
                id: game.animalId,
                name: game.animalName,
                selected: true
              }
            }
          }
        })
      })

      const array = this.flatten(games)
      const language = this.$store.getters['ui/language']
      this.games = array.sort((a, b) => a.name.localeCompare(b.name, language, { sensitivity: 'base' }))
    },

    updateChart () {
      const series = {}

      this.buildSeriesForSelectedGame(series)
      this.limitCurrentYearToCurrentMonth(series)

      let seriesArray = this.flatten(series)

      this.sumMonthlyCounts(seriesArray)

      seriesArray.sort((a, b) => Number(a.name) - Number(b.name)) // Sort oldest to newset
      seriesArray = seriesArray.slice(-5) // We'll only show the last 5 years

      this.series = seriesArray
    },

    buildSeriesForSelectedGame (series) {
      const gameMap = this.getGameMap()

      this.events.forEach(event => {
        const year = event.timestamp.getFullYear()

        let seriesForYear = series[year]
        if (seriesForYear === undefined) {
          seriesForYear = {
            name: year,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }

          series[year] = seriesForYear
        }

        if (this.hasPro) {
          const month = event.timestamp.getMonth()

          event.games.forEach(game => {
            if (isValidGame(game)) {
              if (gameMap[game.animalId].selected) {
                seriesForYear.data[month] += game.count
              }
            }
          })
        }
      })

      return series
    },

    limitCurrentYearToCurrentMonth (series) {
      const seriesForCurrentYear = series[this.$dayjs().year()]

      if (seriesForCurrentYear !== undefined) {
        const month = this.$dayjs().month()
        seriesForCurrentYear.data = seriesForCurrentYear.data.slice(0, month + 1)
      }
    },

    sumMonthlyCounts (series) {
      series.forEach(series => {
        for (let i = 1; i < series.data.length; i++) {
          series.data[i] = series.data[i - 1] + series.data[i]
        }
      })
    },

    updateMonths () {
      this.months = []

      for (let i = 0; i < 12; i++) {
        this.months.push(this.$dayjs().month(i).format('MMM'))
      }
    },

    getGameMap () {
      const map = {}

      this.games.forEach(game => {
        map[game.id] = game
      })

      return map
    },

    onGameSelected (game) {
      this.updateChart()
    }
  }
}
</script>
