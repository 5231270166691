<template>
  <div class="box dashboard-map-card" :class="{ 'is-dimmed': isFullscreen }">
    <DashboardMapDialog v-if="isFullscreen" />
    <DashboardMap v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DashboardMap from './DashboardMap.vue'
import DashboardMapDialog from './DashboardMapDialog.vue'

export default {
  components: {
    DashboardMap,
    DashboardMapDialog
  },

  computed: {
    ...mapGetters({
      isFullscreen: 'map/settings/isFullscreen'
    })
  }
}
</script>

<style scoped>
.dashboard-map-card {
  height: 470px;
  background-color: white;
  padding: 0;
  border-radius: 6px !important;
  overflow: hidden;
}
</style>
