<template>
  <DashboardGraphCard :title="$t('huntArea.dashboard.manHoursPerGame')" :subtitle="$t('huntArea.dashboard.pro.manHours')">
    <Apexchart
      id="man-hours-chart"
      type="line"
      height="100%"
      :options="options"
      :series="series">
    </Apexchart>
  </DashboardGraphCard>
</template>

<script>
import { mapGetters } from 'vuex'
import { getYears } from '@/modules/huntArea/report/utils/reportUtils'
import { buildManHoursChartTooltip } from '../utils/manHoursChart'

import VueApexCharts from 'vue-apexcharts'
import DashboardGraphCard from './DashboardGraphCard.vue'

export default {
  components: {
    DashboardGraphCard,
    Apexchart: VueApexCharts
  },

  computed: {
    ...mapGetters({
      hasPro: 'subscription/hasPro'
    }),

    reports () {
      const reports = this.$store.getters['huntarea/reports/getAll']
      return reports || []
    },

    years () {
      return getYears(this.reports)
    },

    series () {
      return [{
        name: this.$i18n.t('huntArea.dashboard.game.killed'),
        type: 'column',
        data: this.years.map(year => this.hasPro ? this.getGameCountForYear('kill', year) : 0)
      }, {
        name: this.$i18n.t('huntArea.dashboard.game.observed'),
        type: 'column',
        data: this.years.map(year => this.hasPro ? this.getGameCountForYear('observation', year) : 0)
      }, {
        name: this.$i18n.t('huntArea.dashboard.time.time'),
        type: 'area',
        data: this.years.map(year => this.hasPro ? this.getManHoursForYear(year) : 0)
      }]
    },

    options () {
      return {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        markers: {
          size: 3,
          colors: ['', '', '#687b81']
        },
        stroke: {
          width: [1, 1, 2],
          curve: 'smooth'
        },
        colors: [
          '#f1a269', '#687b81', 'rgba(240, 240, 240)'
        ],
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        tooltip: {
          shared: true,
          custom: buildManHoursChartTooltip
        },
        xaxis: {
          categories: this.years,
          tooltip: {
            enabled: false
          }
        },
        yaxis: [
          {
            seriesName: this.$i18n.t('huntArea.dashboard.game.killed'),
            title: {
              text: 'Vilt'
            },
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: 'rgb(150, 150, 150)'
            },
            decimalsInFloat: 0
          },
          {
            seriesName: this.$i18n.t('huntArea.dashboard.game.killed'),
            show: false
          },
          {
            seriesName: this.$i18n.t('huntArea.dashboard.time.time'),
            title: {
              text: this.$i18n.t('huntArea.dashboard.time.time')
            },
            opposite: true,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: 'rgb(150, 150, 150)'
            },
            decimalsInFloat: 0
          }
        ]
      }
    }
  },

  methods: {
    getGameCountForYear (eventType, year) {
      let count = 0

      this.reports.forEach(report => {
        report.events.forEach(event => {
          if (event.type === eventType && event.timestamp.getFullYear() === year) {
            event.games.forEach(game => {
              count += game.count
            })
          }
        })
      })

      return count
    },

    getTotalHoursForYear (year) {
      let hours = 0

      this.reports.forEach(report => {
        if (report.startsAt.getFullYear() === year) {
          hours += report.hours
        }
      })

      return hours
    },

    getManHoursForYear (year) {
      const hours = this.getTotalHoursForYear(year)
      const kills = this.getGameCountForYear('kill', year)
      const observations = this.getGameCountForYear('observation', year)
      const total = kills + observations

      return total > 0 ? hours / total : 0
    }
  }
}
</script>

<style scoped>
.statistics-panel {
  width: 100%;
  height: 200px;
  font-size: 0.7rem;
}

.statistics-panel .statistics-panel-heading {
  font-weight: bold;
  text-align: center;
}
</style>

<style>
/* These styles must be made global in order to be used by Apexcharts. */
#man-hours-chart .apexcharts-tooltip {
  padding: 1rem;
}

#man-hours-chart .apexcharts-tooltip .man-hours-title {
  font-weight: bold;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

#man-hours-chart .apexcharts-tooltip table td:nth-child(2) {
  padding-left: 10px;
}
</style>
