import { i18n } from '@/i18n.js'
import store from '@/store'

/**
 * It's ugly and dangerous to import the store like this. But this is the only way to get access to the needed
 * date in the callback to build the tooltip.
 */
function getTotalHoursForYear (year) {
  const reports = store.getters['huntarea/reports/getAll'] || []

  let hours = 0

  reports.forEach(report => {
    if (report.startsAt.getFullYear() === year) {
      hours += report.hours
    }
  })

  return hours
}

// Creates a custom tooltip popover for the man-hours chart
function buildManHoursChartTooltip (params) {
  const { series, dataPointIndex, w } = params

  // Translations
  const killedGameTitle = i18n.t('huntArea.dashboard.game.killed')
  const observedGameTitle = i18n.t('huntArea.dashboard.game.observed')
  const allGameTitle = i18n.t('huntArea.dashboard.game.all')
  const totalTimeTitle = i18n.t('huntArea.dashboard.time.total')
  const timePerGameTitle = i18n.t('huntArea.dashboard.time.perGame')

  const pcsUnit = i18n.t('huntArea.dashboard.units.pcs')
  const hoursUnit = i18n.t('huntArea.dashboard.units.hours')
  const minutesUnit = i18n.t('huntArea.dashboard.units.minutes')

  // Calculation
  const year = w.globals.categoryLabels[dataPointIndex]

  const kills = series[0][dataPointIndex]
  const observations = series[1][dataPointIndex]
  const totalEvents = kills + observations
  const totalHours = getTotalHoursForYear(year)
  const hoursPerGame = totalEvents > 0 ? totalHours / totalEvents : 0

  const manHours = Math.floor(hoursPerGame)
  const manHoursMinutes = Math.round((hoursPerGame - manHours) * 60)

  let manHoursValue = ''
  if (manHours > 0) manHoursValue += `${manHours} ${hoursUnit} `
  if (manHoursMinutes > 0) manHoursValue += `${manHoursMinutes} ${minutesUnit}`
  if (manHoursValue === '') manHoursValue = '-'

  return `
    <div>
      <div class="man-hours-title">${year}</div>
      <table>
        <tbody>
          <tr>
            <td>${killedGameTitle}:</td>
            <td>
              <strong>${kills} ${pcsUnit}</strong>
            </td>
          </tr>

          <tr>
            <td>${observedGameTitle}:</td>
            <td>
              <strong>${observations} ${pcsUnit}</strong>
            </td>
          </tr>

          <tr>
            <td>${allGameTitle}:</td>
            <td>
              <strong>${kills + observations} ${pcsUnit}</strong>
            </td>
          </tr>

          <tr>
            <td>${totalTimeTitle}:</td>
            <td>
              <strong>${totalHours} ${hoursUnit}</strong>
            </td>
          </tr>

          <tr>
            <td>${timePerGameTitle}:</td>
            <td>
              <strong>${manHoursValue}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>`
}

export {
  buildManHoursChartTooltip
}
