<template>
  <Map :zoom-buttons="true" class="map">
    <DashboardMapToolbar :games="games" />

    <DashboardMapHeatmap
      v-if="hasPro"
      :types="['kill', 'after_search']"
      :gradient="['rgba(0,0,0,0)', '#ff0000', '#ff0000']"
      :games="games">
    </DashboardMapHeatmap>

    <DashboardMapHeatmap
      v-if="hasPro"
      :types="['observation']"
      :gradient="['rgba(0,0,0,0)', '#ffff00', '#ffff00']"
      :games="games">
    </DashboardMapHeatmap>

    <DashboardMapSlider />

    <MapBoundary
      v-if="boundary !== null"
      :coordinates="boundary.coordinates">
    </MapBoundary>

    <MapMarker
      v-for="marker in markers"
      :key="marker.id"
      :name="marker.name"
      :icon="markerIcon(marker)"
      :location="marker.location"
      :show-label="false"
      :icon-size="16">
    </MapMarker>

    <DashboardRequiresSubscriptionOverlay
      :title="$t('huntArea.dashboard.pro.heatMap.title')"
      :subtitle="$t('huntArea.dashboard.pro.heatMap.subtitle')"
      :show-cta="true">
      <UpgradeSubscriptionBadge class="dashboard-map-upgrade-subscription-badge" />
    </DashboardRequiresSubscriptionOverlay>
  </Map>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'
import { getUniqueGamesFromReports } from '@/modules/huntArea/report/utils/reportUtils'

import Map from '@/modules/map/components/Map.vue'
import MapBoundary from '@/modules/map/components/MapBoundary.vue'
import MapMarker from '@/modules/map/components/MapMarker.vue'

import UpgradeSubscriptionBadge from '@/modules/subscription/components/UpgradeSubscriptionBadge.vue'

import DashboardMapToolbar from './DashboardMapToolbar.vue'
import DashboardMapSlider from './DashboardMapSlider.vue'
import DashboardMapHeatmap from './DashboardMapHeatmap.vue'
import DashboardRequiresSubscriptionOverlay from '../DashboardRequiresSubscriptionOverlay.vue'

export default {
  components: {
    Map,
    MapBoundary,
    MapMarker,

    UpgradeSubscriptionBadge,

    DashboardMapToolbar,
    DashboardMapSlider,
    DashboardMapHeatmap,
    DashboardRequiresSubscriptionOverlay
  },

  data () {
    return {
      games: []
    }
  },

  computed: {
    ...mapGetters({
      boundary: 'map/boundary/boundary',
      markers: 'map/markers/markers',
      reports: 'huntarea/reports/getAll',
      eventType: 'huntarea/dashboard/getEventType',
      hasPro: 'subscription/hasPro'
    })
  },

  created () {
    if (this.reports !== null) {
      this.updateGames()
    } else {
      this.$watch('reports', () => {
        this.updateGames()
      })
    }
  },

  methods: {
    markerIcon (marker) {
      return getMarkerTypeIconUrl(marker.type)
    },

    updateGames () {
      const reports = this.reports || [] // Can be null when switching between hunting grounds
      const language = this.$store.getters['ui/language']
      this.games = getUniqueGamesFromReports(reports, language)
    }
  }
}
</script>

<style scoped>
.map {
  height: 100%;
  position: relative;
}

.dashboard-map-upgrade-subscription-badge {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
}
</style>
